<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockFormMaas = CmsBlock & {
  slots: Array<
    CmsSlot & {
    slot: "content";
  }
  >;
};

const props = defineProps<{
  content: CmsBlockFormMaas;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("form");

const { getConfigValue } = useCmsElementConfig(slot);
const formType: any = getConfigValue("formType");
</script>

<template>
  <div class="s-gutter">
    <div class="c-width-small">
      <CmsElementFormMaas :type="formType" :config="slot?.config" />
    </div>
  </div>
</template>
